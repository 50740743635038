import React, { useEffect, useState, useRef } from 'react';
import { Box, HStack, Text, IconButton, Button, Link } from "@chakra-ui/react";

function ViewportSensorIntegration() {
  const [count, setCount] = useState(0);
  const [inViewport, setInViewport] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };

    const observer = new IntersectionObserver(([entry]) => {
      setInViewport(entry.isIntersecting);
    }, options);

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {

    
    function handleMotion(event) {
      if (inViewport && event.accelerationIncludingGravity.x !== null && event.accelerationIncludingGravity.y
        !== null && event.accelerationIncludingGravity.z !== null) {
        setCount(count => count + 1);
      }
    }

    window.addEventListener('devicemotion', handleMotion);

    return () => window.removeEventListener('devicemotion', handleMotion);
  }, [inViewport]);

  useEffect(() => {
    function handleClick() {
      if (inViewport) {
        setCount(count => count + 1);
      }
    }

    window.addEventListener('pointerdown', handleClick);

    return () => window.removeEventListener('pointerdown', handleClick);
  }, [inViewport]);

  useEffect(() => {
    function handleGeolocation(position) {
      if (inViewport) {
        setCount(count => count + 1);
      }
    }

    navigator.geolocation.watchPosition(handleGeolocation);

    return () => navigator.geolocation.clearWatch(handleGeolocation);
  }, [inViewport]);

  return (
    <Box ref={ref} h="400px" w="400px" bg="tomato" justifyContent={"center"} alignItems={"center"} display={"flex"} m={2} borderRadius={10}>
        <Text fontSize="xl" fontWeight="bold" color="white">
            {count}
        </Text>
    </Box>
  );
}

export default ViewportSensorIntegration;