import react from "react";

export default function ChildComponent({ data, setClick, goInner, selectedBubbles }) {
  return (
    <div
    className={selectedBubbles.includes(data) ? "childComponentSelected" : "childComponent"}
      onClick={() => {
        setClick(data);
        goInner(true);
      }}
    >
      {data}
    </div>
  );
}
