import React, { useState, useEffect } from 'react';
import { RiThumbUpLine, RiThumbDownLine } from "react-icons/ri";
import {
    ButtonGroup,
    Icon,
} from "@chakra-ui/react";

import { DataStore } from '@aws-amplify/datastore';
import { Interactions } from '../../../models';
import { Auth } from 'aws-amplify';

function Feedback({ widgetName, sessionContext, clicks}) {

    const [userID, setUserID] = useState("");
    const [userRole, setUserRole] = useState("");

    useEffect(() => {
        const getUserID = async () => {
            const user = await Auth.currentAuthenticatedUser();
            setUserID(user.attributes.sub);
            setUserRole(user.attributes['custom:role']);
        };
        getUserID();
    }, []);

    const getTime = () => {
        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+' '+time;
        return dateTime;
    }

    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

    async function submitNegativeFeedback() {
        var complete = await DataStore.save(
            new Interactions({
                "userSub": userID,
                "long": sessionContext.sessionContext.position.longitude,
                "lat": sessionContext.sessionContext.position.latitude,
                "time": getTime(),
                "feedbackType": "NEGATIVE",
                "interactionType": "FEEDBACK",
                "userID": userID,
                "widgetName": widgetName,
                "duration": sessionContext.sessionContext.duration,
                "clicks": clicks,
                "UserRole": userRole.toUpperCase()
            })
        );
        setFeedbackSubmitted(true);
        console.log("Feedback submitted");
        console.log(complete);
    }

    async function submitPositiveFeedback() {
        var complete = await DataStore.save(
            new Interactions({
                "userSub": userID,
                "long": sessionContext.sessionContext.position.longitude,
                "lat": sessionContext.sessionContext.position.latitude,
                "time": getTime(),
                "feedbackType": "POSITIVE",
                "interactionType": "FEEDBACK",
                "userID": userID,
                "widgetName": widgetName,
                "duration": sessionContext.sessionContext.duration,
                "clicks": clicks,
                "UserRole": userRole.toUpperCase()
            })
        );
        setFeedbackSubmitted(true);
        console.log("Feedback submitted");
        console.log(complete);
    }


    return (
        <div>
            {feedbackSubmitted === false &&
                <ButtonGroup gap='1'>
                    {feedbackSubmitted === false && userID !== "" && sessionContext.sessionContext.position.longitude !== undefined &&
                    <>
                        <Icon as={RiThumbUpLine} w={4} h={4} color={"whiteAlpha.700"} onClick={submitPositiveFeedback} />
                        <Icon as={RiThumbDownLine} w={4} h={4} color={"whiteAlpha.700"} onClick={submitNegativeFeedback} />
                    </>
                    }
                </ButtonGroup>
            }
        </div>
    );
};

export default Feedback;