export const tags = [
    { id: 1, name: "Transcripts", slug: "Transcripts" },
    { id: 2, name: "News", slug: "News" },
    { id: 4, name: "Printing", slug: "Printing" },
    { id: 5, name: "Book Library", slug: "BookLibrary" },
    { id: 6, name: "Nearest Bus", slug: "NearestBus" },
    { id: 9, name: "Weather", slug: "Weather" },
    { id: 11, name: "Finance", slug: "Finance" },
    { id: 13, name: "Lectures", slug: "Lectures" },
    { id: 14, name: "Modules", slug: "Modules" },
    { id: 15, name: "Education", slug: "Education" },
    { id: 16, name: "Schedule", slug: "Schedule" },
    { id: 17, name: "Events", slug: "Events" },
    { id: 18, name: "Transport", slug: "Transport" },
    { id: 19, name: "Library", slug: "Library" },
    { id: 20, name: "Resources", slug: "Resources" },
    { id: 21, name: "Alerts", slug: "Alerts" },
    { id: 22, name: "Calendar", slug: "Calendar" },
    { id: 23, name: "Exams", slug: "Exams" },
    { id: 24, name: "Payments", slug: "Payments" },
    { id: 25, name: "Accounts", slug: "Accounts" },
    { id: 26, name: "Attendance", slug: "Attendance" },
    { id: 27, name: "Grades", slug: "Grades" },
    { id: 28, name: "Student Life", slug: "StudentLife" },
    { id: 29, name: "Timetable", slug: "Timetable" },
    { id: 30, name: "Map", slug: "Map" }
];
