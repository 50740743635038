import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider, extendTheme, ColorModeScript } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools';

const root = ReactDOM.createRoot(document.getElementById('root'));

const styles = {
  global: props => ({
    body: {
      bg: mode('white', 'black')(props),
    },
  }),
};

const theme = extendTheme({
  styles,
});

root.render(
  <ChakraProvider theme={theme}>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ChakraProvider>
);