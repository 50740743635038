import { Flex, Heading, Button, Tag, TagLeftIcon, TagLabel } from "@chakra-ui/react";
import React from "react";
import { AddIcon } from '@chakra-ui/icons'

export default function tagSelection() {

    // navigate to the next page
    const navigateToNextPage = () => {
        window.location.href = "/complete";
    };


    return (
        <div>
            <Flex direction="column" align="center" justify="center" maxWidth="400px" height="100vh" margin="auto" mx={10}>
                <Heading as="h1" size="lg" mb={6}>
                    Personalise iLancaster
                </Heading>
                <Tag size={"xs"} key={"item"} variant='subtle' colorScheme='cyan'>
                    <TagLeftIcon boxSize='12px' as={AddIcon} />
                    <TagLabel>Cyan</TagLabel>
                </Tag>
                <Button colorScheme="red" variant="solid" size="md" mt="4" px={9} onClick={navigateToNextPage}>
                    Done
                </Button>
            </Flex>
        </div>
    );
};