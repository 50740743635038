import React, { useState, useEffect } from 'react';
import { Box, HStack, Text, IconButton, Link } from "@chakra-ui/react";
import { RiThumbDownLine, RiThumbUpLine } from "react-icons/ri";
import Feedback from './shared/Feedback';

import { DataStore } from '@aws-amplify/datastore';
import { Interactions } from '../../models';
import { Auth } from 'aws-amplify';

function NewsWidget(sessionContext) {

    const [count, setCount] = useState(0);
    const [clickCounter, setClickCounter] = useState(0);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [intersectionObserver, setIntersectionObserver] = useState(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsTimerRunning(true);
                } else {
                    setIsTimerRunning(false);
                }
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: [0, 1]
            }
        );

        setIntersectionObserver(observer);

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (!intersectionObserver) {
            return;
        }

        const widgetEl = document.getElementById(NewsWidget.name);
        intersectionObserver.observe(widgetEl);

        return () => {
            intersectionObserver.unobserve(widgetEl);
        };
    }, [intersectionObserver]);

    useEffect(() => {
        let timerId = null;

        if (isTimerRunning) {
            timerId = setInterval(() => {
                setCount(count + 1);
            }, 1000);
        }

        return () => {
            clearInterval(timerId);
        };
    }, [isTimerRunning, count]);

    const [userID, setUserID] = useState("");
    const [userRole, setUserRole] = useState("");

      useEffect(() => {
          const getUserID = async () => {
              const user = await Auth.currentAuthenticatedUser();
              setUserID(user.attributes.sub);
              setUserRole(user.attributes['custom:role']);
          };
          getUserID();
      }, []);
  
      const getTime = () => {
        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+' '+time;
        return dateTime;
    }

    

    async function logInteraction () {
        await DataStore.save(
            new Interactions({
                "userSub": userID,
                "long": sessionContext.sessionContext.position.longitude,
                "lat": sessionContext.sessionContext.position.latitude,
                "time": getTime(),
                "feedbackType": "USEFUL_WIDGET",
                "interactionType": "CLICKED",
                "userID": userID,
                "duration": count,
                "clicks": clickCounter,
                "widgetName": "News Widget",
                "UserRole": userRole.toUpperCase()
            })
        );
    }

    return (
        <>
            <Box
                id={NewsWidget.name}
                w="100%"
                h="200px"
                bg={`linear-gradient(to bottom, ${'#214456'}, ${'#214456'})`}
                borderRadius="15px"
                px={4}
                py={3}
                onClick={() => setClickCounter(clickCounter + 1)}
            >
                <HStack alignItems="center" justifyContent="space-between">
                    <Text fontSize="md" fontWeight="bold" mt={0} color={"whiteAlpha.700"}>
                        News
                    </Text>
                    <Feedback widgetName="News Widget" sessionContext={sessionContext} clicks={clickCounter} />
                </HStack>
            </Box>
        </>
    );
}

export default NewsWidget;



