import { Flex, Heading, Button, Text, Box, Center, HStack, Icon, Input, Select } from "@chakra-ui/react";
import React from "react";
import { Link, redirect } from "react-router-dom";

import { Auth } from 'aws-amplify';


import { BiUser } from "react-icons/bi";

const GetStarted = () => {


    const [givenName, setGivenName] = React.useState('');
    const [college, setCollege] = React.useState('');
    const [role, setRole] = React.useState('');
    const [error, setError] = React.useState('');

    async function handleSubmit(event) {
        event.preventDefault();
        const user = await Auth.currentAuthenticatedUser();

        if (givenName === '') {
            setError('Please enter your given name');
            return;
        }

        if (college === '') {
            setError('Please select your college');
            return;
        }

        if (role === '') {
            setError('Please select your role');
            return;
        }
        
        if (givenName.length > 50) {
            setError('Given name must be less than 50 characters');
            return;
        }


        await Auth.updateUserAttributes(user, {
            'given_name': givenName,
            'custom:college': college,
            'custom:getstarted': "true",
            'custom:role': role
        });

        console.log("New user attributes:")
        console.log(user);
        window.location.reload();
    }

    return (
        <Flex direction="column" align="center" justify="center" maxWidth="400px" height="100vh" margin="auto" mx={10}>

            <Icon as={BiUser} w={12} h={12} color='black' mb={7} />

            <Heading as="h1" size="lg" mb={6}>
                Setup your profile
            </Heading>

            <Input placeholder="First name" size="md" mb={5} onChange={e => setGivenName(e.target.value)} />

            <Select placeholder="Select college" size="md" mb={5} onChange={e => setCollege(e.target.value)}>
                <option value="Grizedale">Grizedale</option>
                <option value="Bowland">Bowland</option>
                <option value="Cartmel">Cartmel</option>
                <option value="County">County</option>
                <option value="Furness">Furness</option>
                <option value="Fylde">Fylde</option>
                <option value="Graduate">Graduate</option>
                <option value="Lonsdale">Lonsdale</option>
                <option value="Pendle">Pendle</option>
            </Select>

            <Select placeholder="Select role" size="md" mb={5} onChange={e => setRole(e.target.value)}>
                <option value="Student">Student</option>
                <option value="Staff">Staff</option>
            </Select>

            {error && <Text color="red.500" mb={5}>
                {error}
            </Text>}

            <Button colorScheme="red" variant="solid" size="md" mt="4" px={9} onClick={handleSubmit}>
                Get started
            </Button>
        </Flex>
    );
};

export default GetStarted;