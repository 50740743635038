import React, { useState, useEffect } from 'react';
import {
  Box,
  Center,
  FormControl,
  FormLabel,
  Select,
  Text,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
  VStack,
} from '@chakra-ui/react';

const user_ids = [
  '2fe4935f-935f-4b60-9bf9-77a963bfac8c',
  '8c6b5358-d8a8-4335-82f7-1a996a3d578f',
  '00db6c9b-fe33-422e-9540-428855892884',
  '7de35572-872d-4040-b3b6-ca24add66ff2',
];

const App = () => {
  const [userId, setUserId] = useState(user_ids[0]);
  const [sessionDuration, setSessionDuration] = useState(0);
  const [numWidgetsRated, setNumWidgetsRated] = useState(0);
  const [recAccuracy, setRecAccuracy] = useState(0);
  const [timerId, setTimerId] = useState(null);

  useEffect(() => {
    if (!timerId) {
      const id = setInterval(() => {
        setSessionDuration((prevDuration) => prevDuration + 1);
      }, 1000);
      setTimerId(id);
    }
    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [timerId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    clearInterval(timerId);
    setTimerId(null);
    console.log({ userId, sessionDuration, numWidgetsRated, recAccuracy });
  };

  return (
    <Center minHeight="100vh">
      <Box borderWidth={1} borderRadius="lg" p={4} width="md">
        <Text fontSize="2xl" mb={4} textAlign="center">
          Widgets Review Form
        </Text>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>User ID:</FormLabel>
              <Select value={userId} onChange={(e) => setUserId(e.target.value)}>
                {user_ids.map((id) => (
                  <option key={id} value={id}>
                    {id}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Text>Session Duration: {sessionDuration} seconds</Text>
            <FormControl>
              <FormLabel>Number of Widgets Rated:</FormLabel>
              <Input
                type="number"
                value={numWidgetsRated}
                onChange={(e) => setNumWidgetsRated(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Recommendation Accuracy:</FormLabel>
              <Text fontSize="2xl" mb={2} textAlign="center"> {recAccuracy} </Text>
              <Slider
                value={recAccuracy}
                min={0}
                max={10}
                step={0.1}
                onChange={(value) => setRecAccuracy(value)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box color="tomato" />
                </SliderThumb>
              </Slider>
            </FormControl>
            <Button type="submit" colorScheme="teal">
                Submit
            </Button>
            </VStack>
        </form>
        </Box>
    </Center>
    );
};

export default App;