import { Flex, Heading, Button, Text, Box, Center, HStack, Icon } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { useNavigate } from "react-router-dom";

import { HiOutlineSparkles } from "react-icons/hi";

const GetStarted = () => {

    const navigate = useNavigate();

    async function handleSubmit(event) {
        event.preventDefault();
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
            'custom:getstarted': "true"
        });
        console.log("New user attributes:")
        console.log(user);
        navigate('/personalise');
    }

    return (
        <Flex direction="column" align="center" justify="center" maxWidth="400px" height="100vh" margin="auto" mx={10}>

            <Icon as={HiOutlineSparkles} w={12} h={12} color='black' mb={7} />

            <Heading as="h1" size="lg" mb={6}>
                Personalise iLancaster
            </Heading>

            <HStack mb={5}>
                <Box w='50px' justifyContent="flex-start" align="center">
                        <Icon as={HiOutlineSparkles} w={8} h={8} color='#87A561' />
                </Box>
                <Box flex='1'>
                    <Text fontSize="sm" fontWeight={"bold"} textColor={"black"} align="left">
                        Item 1
                    </Text>
                    <Text fontSize="xs" textColor={"#4F4F4F"} align="left">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                    </Text>
                </Box>
            </HStack>

            <HStack mb={5}>
                <Box w='50px' justifyContent="flex-start" align="center">
                        <Icon as={HiOutlineSparkles} w={8} h={8} color='#87A561' />
                </Box>
                <Box flex='1'>
                    <Text fontSize="sm" fontWeight={"bold"} textColor={"black"} align="left">
                        Item 1
                    </Text>
                    <Text fontSize="xs" textColor={"#4F4F4F"} align="left">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                    </Text>
                </Box>
            </HStack>

            <HStack mb={5}>
                <Box w='50px' justifyContent="flex-start" align="center">
                        <Icon as={HiOutlineSparkles} w={8} h={8} color='#87A561' />
                </Box>
                <Box flex='1'>
                    <Text fontSize="sm" fontWeight={"bold"} textColor={"black"} align="left">
                        Item 1
                    </Text>
                    <Text fontSize="xs" textColor={"#4F4F4F"} align="left">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.
                    </Text>
                </Box>
            </HStack>

            <Button colorScheme="red" variant="solid" size="md" mt="4" px={9} onClick={handleSubmit}>
                Get started
            </Button>
        </Flex>
    );
};

export default GetStarted;