

/* src/App.js */
import React, { useEffect, useState } from 'react'
import { Amplify, API, graphqlOperation } from 'aws-amplify'
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import GetStarted from './views/GetStarted'
import OnBoarding from './views/OnBoarding'
import Selection from './views/Selection'
import TagSelection from './views/TagSelection'
import Complete from './views/Complete'
import Error from './views/Error'
import Today from './views/Today'
import Data from './views/Data'
import StudyFeedback from './views/StudyFeedback'

import awsExports from "./aws-exports";

Amplify.configure(awsExports);

const initialState = { name: '', description: '' }

const App = ({ signOut, user }) => {

  var given_name = user.attributes.given_name;
  var college = user.attributes['custom:college'];
  var personaliseOnboarded = user.attributes['custom:getstarted'];
  const [onboarded, setOnboarded] = useState(false);

  useEffect(() => {
    if (given_name && college !== 'undefined') {
      console.log("user has a given_name");
      setOnboarded(true);
    } else {
      console.log("user does not have a first name");
      setOnboarded(false);
    }
  }, []);

  useEffect(() => {
    if (personaliseOnboarded === 'true') {
      console.log("user has completed personalisation");
      setOnboarded(true);
    } else {
      console.log("user has not completed personalisation");
      setOnboarded(false);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={onboarded ? <Today user={user} /> : <OnBoarding />} />
        <Route path="/personalise" element={personaliseOnboarded === "true" ? <Selection user={user} /> : <GetStarted />} />
        <Route path="/tag-selection" element={<TagSelection />} />
        <Route path="/complete" element={<Complete />} />
        <Route path="/dataStream" element={<Data />} />
        <Route path="/studyFeedback" element={<StudyFeedback />} />
        <Route path="/error" element={<Error />} />
      </Routes>
    </Router>
  )
}

export default withAuthenticator(App);