import React, { useEffect, useState } from "react";

import { Auth } from 'aws-amplify';
import { DataStore } from '@aws-amplify/datastore';
import { Interactions, User } from '../models';

import download from 'downloadjs';
import Papa from 'papaparse';

import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    Button,
    Link,
    Heading,
    Badge,
    Center,
} from "@chakra-ui/react";

function Data () {
    const [filteredModels, setFilteredModels] = useState([]);
    const [currentTags, setCurrentTags] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredUser, setFilteredUser] = useState([]);
    const [suggestedWidgets, setSuggestedWidgets] = useState([]);

    useEffect(() => {
        saveInteraction();
    }, []);


    async function saveInteraction() {
        const user = await Auth.currentAuthenticatedUser();
        var userID = user.attributes.sub;

        const models = await DataStore.query(Interactions);
        const userProfile = await DataStore.query(User);

        const filteredModels = models

        setFilteredUser(userProfile.filter(model => model.userSub === userID));
        setCurrentTags(filteredUser.length > 0 ? filteredUser[0].tags : []);

        // extract suggested widgets from user profile
        var suggestedWidgets = [];
        for (var i = 0; i < filteredUser.length; i++) {
            for (var j = 0; j < filteredUser[i].tags.length; j++) {
                if (filteredUser[i].tags[j] === "suggested") {
                    suggestedWidgets.push(filteredUser[i].tags[j + 1]);
                }
            }
        }
        setSuggestedWidgets(suggestedWidgets);

        // get suggested widgets from user profile
        console.log(filteredUser);


        setFilteredModels(filteredModels);
        console.log(filteredModels);
        setLoading(false);
    }

    async function handleDownloadClick() {
        // Convert the data to CSV format
        const csvData = Papa.unparse(
          filteredModels.map((model) => ({
            ...model,
            lat: parseFloat(model.lat),
            long: parseFloat(model.long),
          }))
        );
      
        // Download the data as a CSV file
        download(csvData, "rawData.csv", "text/csv");
      }

      async function handleDeleteClick() {
        // Delete all the data
        const models = await DataStore.query(Interactions);
        for (const modelToDelete of models) {
            await DataStore.delete(modelToDelete);
        }
        // Refresh the page
        window.location.reload();

    }
      
      

    return (
        <Box p={4} w="100%">
            <Heading
                as="h1"
                size="lg"
                fontWeight="bold"
                textAlign="center"
                m={4}
            >
                Interaction Data
            </Heading>
          {loading && <Text>Loading...</Text>}
          {!loading && filteredModels.length === 0 && (
            <Text>No interactions found</Text>
          )}

          {!loading && filteredModels.length > 0 && (
            <>
              <Table variant="simple" m={4}>
                <Thead>
                  <Tr>
                    <Th>Interaction Type</Th>
                    <Th>Feedback Type</Th>
                    <Th>User ID</Th>
                    <Th>Role</Th>
                    <Th>Widget Name</Th>
                    <Th>Time</Th>
                    <Th>Latitude</Th>
                    <Th>Longitude</Th>
                    <Th>Duration</Th>
                    <Th>Clicks</Th>
                    <Th>View</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredModels.map((model, index) => (
                    <Tr key={index}>
                      <Td>{model.interactionType}</Td>
                      <Td>{model.feedbackType}</Td>
                      <Td>{model.userID}</Td>
                      <Td>{model.UserRole}</Td>
                      <Td>{model.widgetName}</Td>
                      <Td>{model.time}</Td>
                      <Td>{model.lat}</Td>
                      <Td>{model.long}</Td>
                      <Td>{model.duration}</Td>
                      <Td>{model.clicks}</Td>
                        <Td>    
                            <Link href={"https://www.google.com/maps/search/?api=1&query=" + model.lat + "," + model.long} isExternal>
                                <Button>View</Button>
                            </Link>
                        </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Button onClick={handleDownloadClick}>Download CSV</Button>
              {/* <Button ml={4} onClick={handleDeleteClick}>Delete All</Button> */}
              <Button ml={4} onClick={saveInteraction}>Refresh</Button>
            </>
          )}

            <>
                <Heading as="h2" size="md" fontWeight="bold" textAlign="center" m={4}>
                    Current Tags
                </Heading>
                <Center my={5}>
                    {currentTags.length === 0 && (
                        <Text>No tags found</Text>
                    )}

                    {currentTags.length > 0 && (
                        <>
                            {currentTags.map((tag, index) => (
                                <Badge mr={2} key={index}>{tag}</Badge>
                            ))}
                        </>
                    )}
                </Center>

                <Heading as="h2" size="md" fontWeight="bold" textAlign="center" m={4}>
                    Suggested widgets
                </Heading>
                <Center my={5}>
                    {suggestedWidgets.length === 0 && (
                        <Text>No widgets found</Text>
                    )}

                    {suggestedWidgets.length > 0 && (
                        <>
                            {suggestedWidgets.map((widget, index) => (
                                <Badge mr={2} key={index}>{widget}</Badge>
                            ))}
                        </>
                    )}
                </Center>

                <Heading as="h2" size="md" fontWeight="bold" textAlign="center" mt={8}>
                    All User Tags
                </Heading>

                <Table variant="simple" m={4}>
                    <Thead>
                        <Tr>
                            <Th>User ID</Th>
                            <Th>Tags</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredUser.map((model, index) => (
                            <Tr key={index}>
                                <Td>{model.userSub}</Td>
                                <Td>
                                    {model.tags.map((tag, index) => (
                                        <Badge m={2} key={index}>{tag}</Badge>
                                    ))}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>

                </Table>
            </>


        </Box>
    );
}

export default Data;
