import React, { useState, useEffect } from 'react';
import { Box, HStack, Text, IconButton, VStack, Button, Link } from "@chakra-ui/react";
import { RiThumbDownLine, RiThumbUpLine } from "react-icons/ri";
import Feedback from './shared/Feedback';
import image from '../../assets/library-booking.png';

import { DataStore } from '@aws-amplify/datastore';
import { Interactions } from '../../models';
import { Auth } from 'aws-amplify';

function BookLibrary(sessionContext) {

    const [count, setCount] = useState(0);
    const [clickCounter, setClickCounter] = useState(0);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [intersectionObserver, setIntersectionObserver] = useState(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsTimerRunning(true);
                } else {
                    setIsTimerRunning(false);
                }
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: [0, 1]
            }
        );

        setIntersectionObserver(observer);

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (!intersectionObserver) {
            return;
        }

        const widgetEl = document.getElementById(BookLibrary.name);
        intersectionObserver.observe(widgetEl);

        return () => {
            intersectionObserver.unobserve(widgetEl);
        };
    }, [intersectionObserver]);

    useEffect(() => {
        let timerId = null;

        if (isTimerRunning) {
            timerId = setInterval(() => {
                setCount(count + 1);
            }, 1000);
        }

        return () => {
            clearInterval(timerId);
        };
    }, [isTimerRunning, count]);

    const styles = {
        box: {
          width: '100%',
          height: '150px',
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }
      };


      const [userID, setUserID] = useState("");
      const [userRole, setUserRole] = useState("");

      useEffect(() => {
          const getUserID = async () => {
                const user = await Auth.currentAuthenticatedUser();
                setUserID(user.attributes.sub);
                setUserRole(user.attributes['custom:role']);
          };
          getUserID();
      }, []);
  
      const getTime = () => {
            var today = new Date();
            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            var dateTime = date+' '+time;
            return dateTime;
        }

    

    async function logInteraction () {

        var complete = await DataStore.save(
            new Interactions({
                "userSub": userID,
                "long": sessionContext.sessionContext.position.longitude,
                "lat": sessionContext.sessionContext.position.latitude,
                "time": getTime(),
                "feedbackType": "USEFUL_WIDGET",
                "interactionType": "CLICKED",
                "userID": userID,
                "duration": count,
                "clicks": clickCounter,
                "widgetName": "Library Booking Widget",
                "UserRole": userRole.toUpperCase()
            })
        );
        console.log(complete);
        window.open("https://lancaster-uk.libcal.com/r", "_blank");
    }

    return (
        <>
            <Box
                id={BookLibrary.name}
                w="100%"
                h="340px"
                bg={`linear-gradient(to bottom, ${'#2E5347'}, ${'#204539'})`}
                borderRadius="15px"
                onClick={() => setClickCounter(clickCounter + 1)}
            >
                <Box
                    w="100%"
                    h="130px"
                    px={4}
                    py={3}
                    style={styles.box}
                    borderTopRadius="15px"
                >
                    <VStack alignItems={"flex-start"} justifyContent="end" h="100%">
                        <Box
                            bg={"white"}
                            w="60px"
                            h="19px"
                            borderRadius="100px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Text fontSize="sm" fontWeight="normal" my="30px" color={"black"}>
                                Now
                            </Text>
                        </Box>
                    </VStack>
                </Box>
                <Box w="100%" h="50px" px={4} pt={4} pb={0}>
                    <HStack alignItems="center" justifyContent="space-between">
                        <Text fontSize="md" fontWeight="bold" mb={0} color={"whiteAlpha.800"}>
                            Library Booking
                        </Text>
                        <Feedback widgetName="Book Library" sessionContext={sessionContext} clicks={clickCounter} />
                    </HStack>
                </Box>
                <Box
                    w="100%"
                    h="50px"
                    px={4}
                    py={4}
                >
                    <Text fontSize="xs" fontWeight="normal" mb={5} color={"whiteAlpha.800"}>
                        You can book a room within the library to increase your productivity and concentration.
                    </Text>
                    <Link href="https://lancaster-uk.libcal.com/r" isExternal>
                        <Button
                            w="100%"
                            h="50px"
                            borderRadius="12px"
                            variant={"solid"}
                            colorScheme={"whiteAlpha"}
                            color={"white"}
                            onClick={logInteraction}
                            isDisabled={sessionContext.sessionContext.position.latitude === undefined}
                        >
                            Book Room
                        </Button>
                    </Link>
                </Box>
            </Box>
        </>
    );
}

export default BookLibrary;



