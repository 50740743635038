import React, { useEffect, useState } from "react";
import BubbleUI from "react-bubble-ui";
import "react-bubble-ui/dist/index.css";
import { Heading, Text, Flex, VStack, CircularProgress, CircularProgressLabel, Button, Box, useColorMode } from "@chakra-ui/react";
import { Link, Navigate } from "react-router-dom";
import TagBubble from "../utilities/TagBubble";


import { DataStore } from '@aws-amplify/datastore';
import { User } from '../models';
import { Auth } from 'aws-amplify';

import Child from "../utilities/ChildComponent";
import { tags } from "../utilities/tags";
import "../utilities/bubbleStyles.css";

export default function Selection(user) {

  const [bubble, setBubble] = useState("");
  const [backToInterest, setBackToInterest] = useState(false);

  const [selectedBubbles, setSelectedBubbles] = useState([]);
  const numberSelected = selectedBubbles.length;
  const youvalue = numberSelected * 10;
  const showNext = numberSelected >= 10;
  console.log(selectedBubbles);

  const options = {
    size: 80,
    minSize: 5,
    gutter: 18,
    provideProps: true,
    numCols: 6,
    fringeWidth: 45,
    yRadius: 100,
    xRadius: 100,
    cornerRadius: 50,
    showGuides: false,
    compact: true,
    gravitation: 5
  };

  const handleNext = () => {
    console.log("next");
    console.log(selectedBubbles);
    console.log(tags);
    saveUserTags(selectedBubbles);

  };

  const getUserID = async () => {
    const user = await Auth.currentAuthenticatedUser();
    console.log("UserSub: " + user.attributes.sub);
    return user.attributes.sub;
  };


  async function saveUserTags(tags) {
    var userID = await getUserID();
    tags = await DataStore.save(
      new User({
        "tags": tags,
        "suggestedWidgets": [],
        "classicWidgets": [],
        "userSub": userID
      })
    );
    if (tags) {
      console.log("saved");
      window.location.href = "/complete";
    }
  }

  const handleClick = (bub) => {
    if (selectedBubbles.includes(bub)) {
      setSelectedBubbles(selectedBubbles.filter((item) => item !== bub));
    } else {
      setSelectedBubbles([...selectedBubbles, bub]);
    }
    console.log("click");
  };

  let tagsArray = {};
  tags.forEach((tag) => {
    tagsArray[tag.name] = "some value";
  });

  const children = tags.map((data, i) => {
    return (
      <Child
        data={data.name}
        // if selctedBubbles includes the tag, then set the className to "childComponentSelected" else set it to "childComponent"
        className={selectedBubbles.includes(data) ? "childComponentSelected" : "childComponent"}
        key={i}
        setClick={handleClick}
        goInner={setBackToInterest}
        selectedBubbles={selectedBubbles}
      />
    );
  });


  return (
    <React.Fragment>

      <Flex align="center" justify="center" direction="column" height={"90vh"} width={"100%"} my={"10%"}>

        <VStack spacing={4} m={4} maxW="400px" align={"center"}>
          <Heading as="h1" size="lg" >
            Tell us what you're into.
          </Heading>

          <Text fontSize='sm' align="center" maxW={"250px"}>
            Tap the bubbles that best describe you. You can tap as many as you want.
          </Text>
        </VStack>

        <BubbleUI
          options={options}
          className={"bubbleFrame"}
        >
          {children}
        </BubbleUI>



        <Text fontSize='sm' mt={6}>
          {numberSelected} selected
        </Text>

        <Flex judstify="space-between" maxW="400px" align={"center"}>

          <Link to="/">
            <Button variant="ghost" mr={8}>
              Back
            </Button>
          </Link>
          <CircularProgress value={youvalue} size='80px' thickness='4px' color={showNext ? "green.300" : "red.500"} mt={2}>
            <CircularProgressLabel fontSize='xl'>You</CircularProgressLabel>
          </CircularProgress>

          <Button variant="ghost" ml={8} isDisabled={!showNext} colourScheme={showNext ? "green" : "red"} onClick={handleNext}>
            Next
          </Button>
        </Flex>
      </Flex>
    </React.Fragment>
  );
}
