import { Flex, Heading, Button, Text, Box, Center, HStack, Icon } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { CiCircleCheck } from "react-icons/ci";

export default function complete() {

    // navigate to the next page
    const navigateToNextPage = () => {
        window.location.href = "/";
    };


    return (
        <div>
            <Flex direction="column" align="center" justify="center" maxWidth="400px" height="100vh" margin="auto" mx={10}>
                <Icon as={CiCircleCheck} w={20} h={20} mb={7} />
                <Heading as="h1" size="lg" mb={6} textAlign="center">
                    Your personalisation is complete!
                </Heading>
                <Text fontSize='sm' align="center" maxW={"300px"} mb={6}>
                    We will use a machine learning algorithm to suggest the best widgets for you. Please leave feedback on each widget to help us improve.
                </Text>
                <Button variant="solid" size="md" mt="4" px={9} onClick={navigateToNextPage}>
                    Done
                </Button>
            </Flex>
        </div>
    );
};